import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _03dd0538 = () => interopDefault(import('../src/pages/cookie-policy.vue' /* webpackChunkName: "pages/cookie-policy" */))
const _dd435660 = () => interopDefault(import('../src/pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _e0278a72 = () => interopDefault(import('../src/pages/manage-booking/index.vue' /* webpackChunkName: "pages/manage-booking/index" */))
const _61af436a = () => interopDefault(import('../src/pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _7d57d3f5 = () => interopDefault(import('../src/pages/receipt.vue' /* webpackChunkName: "pages/receipt" */))
const _01f9ff0a = () => interopDefault(import('../src/pages/search.vue' /* webpackChunkName: "pages/search" */))
const _6c91984a = () => interopDefault(import('../src/pages/search-booking/index.vue' /* webpackChunkName: "pages/search-booking/index" */))
const _26f94bd8 = () => interopDefault(import('../src/pages/select-extras/index.vue' /* webpackChunkName: "pages/select-extras/index" */))
const _569df9f2 = () => interopDefault(import('../src/pages/terms-and-conditions.vue' /* webpackChunkName: "pages/terms-and-conditions" */))
const _18d4eb73 = () => interopDefault(import('../src/pages/travel-extras.vue' /* webpackChunkName: "pages/travel-extras" */))
const _96f8dc66 = () => interopDefault(import('../src/pages/unsupported-browser.vue' /* webpackChunkName: "pages/unsupported-browser" */))
const _c73c0bd0 = () => interopDefault(import('../src/pages/manage-booking/cancel/index.vue' /* webpackChunkName: "pages/manage-booking/cancel/index" */))
const _e8319cd6 = () => interopDefault(import('../src/pages/manage-booking/modify.vue' /* webpackChunkName: "pages/manage-booking/modify" */))
const _a4408426 = () => interopDefault(import('../src/pages/manage-booking/receipt.vue' /* webpackChunkName: "pages/manage-booking/receipt" */))
const _31591919 = () => interopDefault(import('../src/pages/manage-booking/update-details.vue' /* webpackChunkName: "pages/manage-booking/update-details" */))
const _c515f418 = () => interopDefault(import('../src/pages/select-extras/fasttrack/index.vue' /* webpackChunkName: "pages/select-extras/fasttrack/index" */))
const _9f5de804 = () => interopDefault(import('../src/pages/select-extras/lounges/index.vue' /* webpackChunkName: "pages/select-extras/lounges/index" */))
const _3a8b82f4 = () => interopDefault(import('../src/pages/select-extras/product/index.vue' /* webpackChunkName: "pages/select-extras/product/index" */))
const _73e2ecfe = () => interopDefault(import('../src/pages/manage-booking/cancel/receipt.vue' /* webpackChunkName: "pages/manage-booking/cancel/receipt" */))
const _4d0232cf = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/cookie-policy",
    component: _03dd0538,
    name: "cookie-policy"
  }, {
    path: "/maintenance",
    component: _dd435660,
    name: "maintenance"
  }, {
    path: "/manage-booking",
    component: _e0278a72,
    name: "manage-booking"
  }, {
    path: "/privacy-policy",
    component: _61af436a,
    name: "privacy-policy"
  }, {
    path: "/receipt",
    component: _7d57d3f5,
    name: "receipt"
  }, {
    path: "/search",
    component: _01f9ff0a,
    name: "search"
  }, {
    path: "/search-booking",
    component: _6c91984a,
    name: "search-booking"
  }, {
    path: "/select-extras",
    component: _26f94bd8,
    name: "select-extras"
  }, {
    path: "/terms-and-conditions",
    component: _569df9f2,
    name: "terms-and-conditions"
  }, {
    path: "/travel-extras",
    component: _18d4eb73,
    name: "travel-extras"
  }, {
    path: "/unsupported-browser",
    component: _96f8dc66,
    name: "unsupported-browser"
  }, {
    path: "/manage-booking/cancel",
    component: _c73c0bd0,
    name: "manage-booking-cancel"
  }, {
    path: "/manage-booking/modify",
    component: _e8319cd6,
    name: "manage-booking-modify"
  }, {
    path: "/manage-booking/receipt",
    component: _a4408426,
    name: "manage-booking-receipt"
  }, {
    path: "/manage-booking/update-details",
    component: _31591919,
    name: "manage-booking-update-details"
  }, {
    path: "/select-extras/fasttrack",
    component: _c515f418,
    name: "select-extras-fasttrack"
  }, {
    path: "/select-extras/lounges",
    component: _9f5de804,
    name: "select-extras-lounges"
  }, {
    path: "/select-extras/product",
    component: _3a8b82f4,
    name: "select-extras-product"
  }, {
    path: "/manage-booking/cancel/receipt",
    component: _73e2ecfe,
    name: "manage-booking-cancel-receipt"
  }, {
    path: "/",
    component: _4d0232cf,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
